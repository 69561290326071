import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import icondata from "../images/icon-data.svg";
import iconroadmap from "../images/icon-roadmap.svg";
import iconmagnifyingglass from "../images/icon-magnifying-glass.svg";
import icongraph from "../images/icon-graph.svg";
import heroimage from "../images/organic-search-audit-hero.jpg";
import audithelpdevice from "../images/organic-search-audit-help-device.png";
import audithelpscorecard from "../images/organic-search-audit-help-scorecard.png";

import "../scss/organicSearchAudit.scss";
import "../scss/auditHeroSection.scss";

const AuditOrganicSearch = () => {
  const metadescription = "Next&Co are an independent SEO Agency specialising in audits, visit our site to find out your search and market trends and how to build a roadmap to success.";
  const metatitle = "SEO Audit, Organic Search Audit - Next&Co";
  const metaurl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription}/>
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>
      <div id="organic-search-audit">
        <Layout id="audit-organic-search">
          <section id="row-one">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <h1>Organic Search Audit</h1>
                  </div>
                    <div className="col-two"
			 style={{
			     display: `flex`,
			     alignItems: `center`,
			 }}>
			<p>
			    Understand the opportunity for your brand in the organic
			    search space and how to capitalise on it
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="audit-hero-section"
            style={{
              backgroundImage: `url(${heroimage})`,
              backgroundPosition: `center center`,
              backgroundAttachment: `fixed`,
            }}
          >
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <div className="list-item">
                      <div className="icon">
                        <img src={iconmagnifyingglass} alt="" />
                      </div>
                      <div className="content">
                        <h2>
                          Search trends
                        </h2>
                        <p>
                          Understand how your audiences are engaging with search and what trends are around the corner
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <div className="icon">
                        <img src={icondata} alt="" />
                      </div>
                      <div className="content">
                        <h2>
                          Market analysis
                        </h2>
                        <p>
                          Assess how your brand is performing in regards to organic share of voice and quantify the value of improved performance
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <div className="icon">
                        <img src={icongraph} alt="" />
                      </div>
                      <div className="content">
                        <h2>
                          Competitor analysis
                        </h2>
                        <p>
                          Get insight into how your competitors are performing in organic search
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <div className="icon">
                        <img src={iconroadmap} alt="" />
                      </div>
                      <div className="content">
                        <h2>
                          Build roadmap
                        </h2>
                        <p>
                          Define both an immediate and long-term roadmap to capture more organic search traffic
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-two">
                    <div className="form-container">
                      <h2>Enquire about an organic search audit for your brand</h2>
                      {/* <h1>{this.props.formSubmitUrl}</h1> */}
                      <form
                        name="os-audit"
                        method="post"
                        action="/osa-thankyou/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                      >
                        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                        <input type="hidden" name="form-name" value="os-audit" />
                        <p hidden>
                          <label>
                            Don’t fill this out:{" "}
                            <input name="bot-field"/>
                          </label>
                        </p>
                        <p></p>
                        <p>
                          <label>
                            Your Name
                            <input
                              type="text"
                              required
                              name="your-name"
                              placeholder="Your name"
                            />
                          </label>
                        </p>
                        <p>
                          <label>
                            URL
                            <input
                              type="text"
                              required
                              name="website-url"
                              placeholder="Website URL"
                            />
                          </label>
                        </p>
                        <p>
                          <label>
                            Email
                            <input
                              type="email"
                              required
                              name="email"
                              placeholder="Your email"
                            />
                          </label>
                        </p>
                        <p>
                          <label>
                            Phone
                            <input
                              type="tel"
                              name="phone"
                              required
                              placeholder="Your phone"
                            />
                          </label>
                        </p>
                        <p>
                          <button className="btn btn-invert" type="submit">
                            Get your audit
                          </button>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="audit-help">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col">
                    <img src={audithelpdevice} alt="" />
                  </div>
                  <div className="col">
                    <img src={audithelpscorecard} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    </>
  )
}

export default AuditOrganicSearch
